.menu-item {
    position: relative
}

.menu-content-wrapper {
    display: inline-block;
    position: absolute;
    top: 32px;
    right: 0px;
    color: black;
    opacity: 1;
    background-color: transparent;
}

.menu-content-arrow {
    top: 5px;
    right: 20px;
}

.menu-content {
    padding: 10px 40px 10px 20px;
    margin-top: 20px;
    border: solid 1px #f7f7f7;
    border-top: solid 2px #009acb;
    box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.2);
    z-index: 999999;
}

.menu-item:focus { outline: none; }